import React from 'react';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Resource from '../components/Resource';
import Subfooter from '../components/Subfooter';
import SurveyCallout from '../components/SurveyCallout';
import Image from '../components/Image';
import { graphql } from 'gatsby';
import { getKeyedResources } from '../utils/resource-helper';

const textOverlay = (
  <div className="community__text_overlay_container">
    <Container fullWidth>
      <div className="community__text_overlay_info">Community connections</div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    The strongest communities are those that take notice of historical data and how
    it affects the present and future.
  </span>
);

const Community = ({ data }) => {
  const { beingSeenOnScreen, invisibleNoMore } = getKeyedResources(data);

  return (
    <Layout title="Community">
      <Hero
        mediaSource="img-lead-triangle-community-connections.png"
        textOverlay={textOverlay}
      />
      <Section className="community__callout-section section--gray">
        <Container fullWidth>
          <Row className="community__callout-row">
            <Column largeOffset={1} size={10}>
              <Callout
                className="community__callout"
                containerClassName="community__callout-container"
              >
                Connecting the community with data
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 section--gray">
        <Container>
          <Row>
            <Column size={10}>
              <p className="text-sans-serif">
                Explore the amazing world of data and get involved in your
                community! No matter what your level of experience, check out
                ready-to-use resources that will help you dive into the use of
                data in everyday life and empower you to share your knowledge
                with others.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className={'section--gray'}>
        <Container>
          <Row>
            <Column size={12}>
              <h2>Getting started with data</h2>
            </Column>
          </Row>
          <Row>
            <Column size={4} className={'community__images'}>
              <Image filename="community-intro@2x.jpg" />
            </Column>
            <Column size={8} className="text-sans-serif">
              <p>
                With so much happening in the world today, it can be difficult
                to process all the information we receive on a daily basis. Find
                out how you can use data to understand and investigate timely
                topics that matter to you.
              </p>
              <p>
                As a community works together to overcome challenges and grow,
                data can be a powerful tool to anchor conversations and align
                diverse views using vital information that contextualizes daily
                life.
              </p>
              <p>
                Data is everywhere around us. You interact with it every single
                day, from creating your monthly budget to searching the
                internet. Below are ready-to-use tools to help you unpack life’s
                data and create meaningful connections –{' '}
                <i>with little to no materials necessary!</i>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={12}>
              <h2>Community briefs</h2>
            </Column>
          </Row>
          <Row>
            <Column size={4}>
              <Resource
                tophat={beingSeenOnScreen.tophat}
                img={beingSeenOnScreen.img}
                title={beingSeenOnScreen.title}
                description={beingSeenOnScreen.description}
                className={beingSeenOnScreen.className}
                actions={beingSeenOnScreen.actions}
              />
            </Column>
            <Column size={4}>
              <Resource
                tophat={invisibleNoMore.tophat}
                img={invisibleNoMore.img}
                title={invisibleNoMore.title}
                description={invisibleNoMore.description}
                className={invisibleNoMore.className}
                actions={invisibleNoMore.actions}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <SurveyCallout />
      <Section className="pt-0 pb-0">
        <Subfooter
          img="footer-community@2x.jpg"
          content={subfooterContent}
          icon="icon-subject.svg"
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query CommunityResources {
    allResourcesJson(filter: { pages: { in: "community" } }) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            moduleName
            download
            noIcon
            asLink
          }
          horizontal
          className
          pages
        }
      }
    }
  }
`;

export default Community;
